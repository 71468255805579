import urlcat from 'urlcat';
import { StaticJsonAPIs } from '~/configs/StaticJsonAPIs';
import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import { ElkUrlu } from '~/modules/SDK/Elk/ElkUrlu';
import { MeAPI } from '~/modules/SDK/me/MeAPI';
import { SignalrEvent } from '~/modules/SDK/Signalr/SignalrEvent';
import { SignalrMethod } from '~/modules/SDK/Signalr/SignalrMethod';
import { StrengthAPI } from '~/modules/strength-state/StrengthAPI';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { __DEV__ } from '~/utils/__DEV__';
import { SignalWallAPI } from '~/modules/signal-wall/SignalWallAPI';
import { OptionsAPI } from '~/modules/options/api/optionsAPI';
import { StockScreenerAPI } from '~/modules/screener/containers/StockScreenerAPI';
import { TvAPIs } from '~/configs/apirc/TvAPIs';
import { MarketDataAPI } from '~/configs/apirc/MarketDataAPI';
import { AdminAPI } from '~/configs/apirc/AdminAPI';
import { LineNotifyAPI } from '~/configs/apirc/LineNotifyAPI';
import { StrategyAPI3 } from '~/configs/apirc/StrategyAPI3';
/** 環境與一級網域設定 */
const defaultsConfig = {
    admin: new AdminAPI(`https://member-auth-v2.run.futures-ai.com/api`),
    lineNotify: new LineNotifyAPI(`https://line-notify.futures-ai.com`),
    signalWall: new SignalWallAPI(`https://signal-wall.futures-ai.com/search/signal-wall`),
    elk: new ElkUrlu('https://elasticsearch.run.futures-ai.com'),
    strategy3: new StrategyAPI3(`https://api.strategy-center.futures-op.com`),
    staticJson: new StaticJsonAPIs(),
    marketDataAPI: new MarketDataAPI(`https://market-data-api.futures-ai.com`),
    /** 不知道如何分類 */
    unclassified: {
        //tradingSummary: 'http://10.81.52.177:8080/api/v1/tradingSummary',
        tradingSummary: new Urlu('https://internal---trading-summary-ytvffgctuq-de.a.run.app/api/v1/tradingSummary'),
        tradingSummaryUploadVersion: new Urlu('https://multi---trading-summary-ytvffgctuq-de.a.run.app'),
        tradeInfoURL: new Urlu('https://trading-event-handler-ytvffgctuq-de.a.run.app/get_tradeinfo'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.unifyTable
         *
         * @deprecated
         */
        unifyTableURL: new Urlu('https://market-data-api.futures-ai.com/unify_table'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.amplitudeStatistic
         *
         * @deprecated
         */
        amplitudeStatisticURL: new Urlu('https://market-data-api.futures-ai.com/amplitude_statistic/'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.diffStatistic
         *
         * @deprecated
         */
        diffStatisticURL: new Urlu('https://market-data-api.futures-ai.com/diff_statistic/'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.taifexOptionsOi
         *
         * @deprecated
         */
        taifexOptionsOiURL: new Urlu('https://market-data-api.futures-ai.com/taifex_options_oi/'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.daddy960.stkfutTradeInfo
         *
         * daddy960 goodway 股期資訊
         *
         * @deprecated
         */
        stkfutTradeinfoURL: new Urlu('https://market-data-api.futures-ai.com/stkfut_tradeinfo/'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.daddy960.stockFuturesWeight
         *
         * 股期權重排行前30
         *
         * @deprecated
         */
        stkfutWeightURL: new Urlu('https://market-data-api.futures-ai.com/stock_futures_weight/'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.daddy960.chip960TradeInfo
         *
         * 股威交易資訊 外資、散戶、自營
         *
         * @deprecated
         */
        daddy960TradeinfoURL: new Urlu('https://market-data-api.futures-ai.com/chip960_tradeinfo/'),
        /** sgx 商品資料 */
        sgxSymbolData: new Urlu('https://market-data-api.futures-ai.com/sgx_futures/'),
    },
    rss: {
        searchURL: new Urlu('https://innews.infohubnews.app/v1'),
    },
    trade: {
        brokerURL: new Urlu('https://order-api.run.futures-ai.com'),
        virtualExchangeURL: new Urlu('https://virtual-exchange.futures-ai.com'),
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.strategy.XXX
         *
         * @deprecated
         */
        strategyCenterURL: new Urlu('https://strategy-center.futures-op.com'),
    },
    tvAPIs: new TvAPIs(`https://tvapi.futures-op.com/v2`),
    tvAPIsOfGCP: new TvAPIs(`https://tvapi.futures-ai.com/v2`),
    stock: {
        /**
         * # ❌ @deprecated
         *
         * ## 改用 apirc.marketDataAPI.stockWeight
         *
         * 股威交易資訊 外資、散戶、自營
         *
         * @deprecated
         */
        stockWeightURL: new Urlu('https://market-data-api.futures-ai.com/stock_weight'),
        /**
         * # 都整理進去 apirc.stock.screenerAPI
         *
         * @deprecated
         */
        stockScreenerURL: new Urlu('https://stock-screener.futures-ai.com/api/v1'),
        screenerAPI: new StockScreenerAPI('https://stock-screener.futures-ai.com/api/v1'),
        virtualExchange: new Urlu('https://watchlist.run.futures-ai.com/api'),
    },
    blog: {
        contentPostsURL: new Urlu('https://blog.futures-ai.com/ghost/api/v2/content/posts'),
        contentPostsSlugURL: new Urlu('https://blog.futures-ai.com/ghost/api/v2/content/posts/slug/:slug'),
    },
    /** Socket 包含 OHLC、options、海期、台指期、Tickets, etc. */
    signalr: {
        event: SignalrEvent,
        method: SignalrMethod,
        wsURL: new Urlu('https://realtime-quote-signalr.futures-op.com/ws'),
    },
    /** 選擇權 */
    options: {
        api: new OptionsAPI(`https://api.futures-op.com/api`),
        opQuotesURL: new Urlu('https://api.futures-op.com/api/options_price'),
        opcontractsURL: new Urlu('https://api.futures-op.com/api/opcontracts'),
        opcontractsUrlGet(params) {
            return urlcat(this.opcontractsURL.baseUrl, {
                from: String((params.from?.toDate().getTime() ?? 0) / 1000),
                to: String((params.to?.toDate().getTime() ?? 0) / 1000),
            });
        },
        optionsgreeksURL: new Urlu('https://api.futures-op.com/api/options_greeks'),
        optionsgreeksUrlGet(params) {
            return urlcat(this.optionsgreeksURL.baseUrl, {
                ...params,
            });
        },
        opbsURL: new Urlu('https://api.futures-op.com/api/v2/opbs'),
        opbsUrlGet(params) {
            return urlcat(this.opbsURL.baseUrl, {
                ...params,
                q_gte: params.q_gte,
                q_lte: params.q_lte,
                m_gte: params.m_gte,
                m_lte: params.m_lte,
                from: String((params.from?.toDate().getTime() ?? 0) / 1000),
                from__DEBUG__: params.from?.format(),
                to: String((params.to?.toDate().getTime() ?? 0) / 1000),
                to__DEBUG__: params.to?.format(),
            });
        },
    },
    me: {
        api: new MeAPI('https://member-auth-v2.run.futures-ai.com/api'),
    },
    /** 五檔掛單與報價 */
    socketBidAsk: {
        socketChannel: 'bidask',
        socketEvent: 'quote',
        socketURL: 'https://realtime-quote.futures-op.com',
    },
    /** 股票、海期、指數的報價 */
    socketOHCL: {
        socketURL: 'https://realtime-quote.futures-ai.com',
        socketEvent: 'quote',
    },
    /** 逐筆成交 */
    socketTicks: {
        socketChannel: 'tick',
        socketEvent: 'quote',
        socketURL: 'https://realtime-quote.futures-op.com',
    },
    /** TVChart `charts_storage_url` */
    chartsStorageUrl: new Urlu('https://saveload.run.futures-ai.com'),
    /** TVChart Datafeed */
    chartDatafeed: {
        /** 當休眠後起床，bars 無法正常載入時，會自行執行 retry 機制 */
        getBarsRetryInterval: 10 * 1000,
    },
    /** 盤勢轉折 */
    pivot: {
        /** Polling 抓取速率 */
        pollingRate: 1000 * 10,
    },
    /**
     * # ❌ @deprecated
     *
     * ## 改用 apirc.marketDataAPI.pivotData
     *
     * ## 改用 apirc.marketDataAPI.vixInfo
     *
     * ## 改用 apirc.marketDataAPI.dashboardData
     *
     * @deprecated
     */
    strength: {
        api: new StrengthAPI('https://market-data-api.futures-ai.com'),
        /** 轉折資訊 Polling 抓取速率 */
        pivotPollingRate: 1000 * 10,
    },
    /** TVChart 伺服器選單 */
    chartServer: [
        {
            historyUrl: 'https://tvapi.futures-op.com/v2',
            label: '伺服器 1 - CHT',
            wsUrl: 'https://tvapi.futures-op.com/v2/ws',
        },
        {
            historyUrl: 'https://tvapi.futures-ai.com/v2',
            label: '伺服器 2 - GCP',
            wsUrl: 'https://tvapi.futures-ai.com/v2/ws',
        },
    ],
};
if (fr_agents.is['futuresai@web']) {
    defaultsConfig.signalr.wsURL = new Urlu('https://realtime-quote-signalr.futures-ai.com/ws');
}
if (__DEV__) {
    defaultsConfig.chartServer.push({
        historyUrl: 'https://tvapi.futures-op.com',
        label: '伺服器 DEV - CHT E01',
        wsUrl: 'https://tvapi.futures-op.com/v2/ws',
    });
}
/** 根據 build 的時候根據 env.AGENT 選擇 config，在 DEV 環境統一使用 defaults */
export const apirc = defaultsConfig;
