import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class StaticJsonAPIs extends Urlu {
    constructor() {
        super('https://storage.googleapis.com');
    }
    get request() {
        return super.request.removeJwt;
    }
    async fetchContractInfo() {
        return this.request.axios
            .get('/symbol-config/contract_info.json')
            .then(res => res.data);
    }
    /** 純股票代碼 */
    async fetchSymbolOfStockToNameMapp() {
        const symbolOfStockToNameMapp = await this.request.axios
            .get('/symbol-config/stock_code_to_chinese.json')
            .then(res => res.data);
        return symbolOfStockToNameMapp;
    }
    /** 純期貨代碼 */
    async fetchSymbolToNameMapp() {
        const symbolToNameMapp = await this.request.axios
            .get('/symbol-config/code_to_chinese.json')
            .then(res => res.data);
        return symbolToNameMapp;
    }
    /** 商品通用工具清單 */
    async fetchCommonSymbolList() {
        const symbolToNameMapp = await this.request.axios
            .get('/symbol-config/common_symbol_list.json')
            .then(res => res.data);
        return symbolToNameMapp;
    }
}
