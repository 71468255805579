import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import { expectType } from '~/utils/tsd';
export class MarketDataAPI extends Urlu {
    /** 轉折資訊 */
    pivotData = new Urlu.Query((params) => {
        return this.request.axios
            .get('/latest_value', {
            params: {
                key: `pivot_${params.symbolStringWithTimeframe.toUpperCase()}`,
            },
        })
            .then(res => res.data);
    });
    /** 期天自制的vix波動率 */
    vixInfo = new Urlu.Query(() => {
        return this.request.axios
            .get('/latest_value', {
            params: { key: 'atm_iv' },
        })
            .then(res => res.data);
    });
    /** 大戶力道、大互掛單 */
    dashboardData = new Urlu.Query((params) => {
        return this.request.axios
            .get('/latest_value', {
            params: {
                key: params.target,
            },
        })
            .then(res => res.data)
            .catch(error => {
            console.error(`[ERROR/fallback] ${error?.message}`);
            const fallback = {
                value: 0,
                display_value: 0,
            };
            return fallback;
        });
    });
    /**
     * @example
     *   //
     *   // return
     *   { 1101: { name: '台泥', percentage: 0.5964 }, 1102: { name: '亞泥', percentage: '0.3525' } , ..., }
     */
    stockWeight = new Urlu.Query((params) => {
        return this.request.axios
            .get(
        // WORKAROUND: 這個後輟斜線一定得加，不然會 cors ERROR
        `/stock_weight/`, { params })
            .then(res => Object.entries(res.data));
    });
    /** 缺註釋 */
    amplitudeStatistic = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/amplitude_statistic`, { params })
            .then(res => res.data);
    });
    /** 缺註釋 */
    unifyTable = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/unify_table`, { params })
            .then(res => res.data);
    });
    /** 缺註釋 */
    diffStatistic = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/diff_statistic`, { params })
            .then(res => res.data);
    });
    /** 目前可用合約、所有履約價格的未平倉量 */
    taifexOptionsOi = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/taifex_options_oi`, { params })
            .then(res => res.data);
    });
    /** 股期權重排行前30 */
    stockFuturesWeight = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/stock_futures_weight`, { params })
            .then(res => res.data);
    });
    /** 股期資訊 */
    stkfutTradeInfo = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/stkfut_tradeinfo`, { params })
            .then(res => res.data);
    });
    /** 專用 */
    daddy960 = {
        /** 股威交易資訊 外資、散戶、自營 */
        chip960TradeInfo: new Urlu.Query((params) => {
            return this.request.axios
                .get(`/chip960_tradeinfo`, { params })
                .then(res => res.data);
        }),
    };
    averageVolume = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/average_volume/`, {
            params: params,
        })
            .then(res => res.data);
    });
    averageAmplitude = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/average_amplitude`, {
            params: params,
        })
            .then(res => res.data);
    });
    sgxSymbolData = new Urlu.Query((params) => {
        return this.request.removeJwt.axios
            .get(`/sgx_futures/`, {
            params: params,
        })
            .then(res => res.data);
    });
}
/* istanbul ignore next */
function TypingTesting() {
    const api = new MarketDataAPI(``);
    //
    //
    const unifyTable = api.unifyTable.useSWR({
        from: 123,
        to: 123,
    }, {
        revalidateOnFocus: false,
        refreshInterval: 15000,
    });
    //
    //
    const stkfutTradeInfo = api.stkfutTradeInfo.useSWR(undefined, {
        revalidateOnFocus: false,
        refreshInterval: 15000,
    });
    expectType(stkfutTradeInfo.data?.[0]?.total);
}
