import useSWR from 'swr';
import { useAsync } from 'react-use';
import urlcat from 'urlcat';
/** ⭐️ 創建一筆 async http request API 之用 */
export class UrluOfQuery {
    constructor(fetcher, options) {
        //
        //
        this.fetch = fetcher;
        /**
         * useSWR 的第一個參數，必須是「唯一的 key as string」
         *
         * 如果我們不同 API 都用同樣的 key 會造成其餘相同 key 的不同 API 打不出去
         *
         * 這裡利用 toString() 來達到自動定義唯一 key 的特性
         */
        const fetchId = this.fetch.toString().replaceAll(/[\s\n:]/gi, '') + (options?.namespace || '');
        //
        //
        this.useAsync = function useAsync_UrluOfQuery(params) {
            const res = useAsync(async () => {
                return await fetcher(params);
            }, [params && urlcat(fetchId, { ...params })]);
            return {
                data: res.value,
                loading: res.loading,
                error: res.error,
            };
        };
        //
        //
        this.useSWR = function useSWR_UrluOfQuery(params, swrConfig) {
            const swrKey = params ? urlcat(fetchId, { ...params }) : fetchId;
            swrConfig?.debug?.('.useSWR($params, $swrConfig)', {
                $params: params,
                $swrConfig: swrConfig,
                swrKey: swrKey,
            });
            const res = useSWR(swrKey, async () => {
                const shouldPreventRequest = swrConfig?.preventRequestIf?.(params);
                if (shouldPreventRequest === true) {
                    swrConfig?.debug?.('.preventRequestIf(): $return', { $return: shouldPreventRequest });
                    return undefined;
                }
                swrConfig?.debug?.('fetcher($params)', { $params: params });
                return await fetcher(params);
            }, swrConfig);
            return {
                data: res.data,
                loading: res.isLoading,
                error: res.error,
            };
        };
    }
    /**
     * ⭐️ axios 的 get 或 post
     *
     * 依最初定義的 function 而定
     */
    fetch;
    /**
     * ⭐️ 給 react 用的 hook
     *
     * 使 react 端開箱即用！不須要再自己封裝一次 hook
     */
    useAsync;
    /**
     * ⭐️ 給 react 用的 hook
     *
     * 使 react 端開箱即用！不須要再自己封裝一次 hook
     */
    useSWR;
}
