import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class StrategyAPI3 extends Urlu {
    /** 這邊專用的 jwt */
    get jwt() {
        return 'futuresai';
    }
    allOverviews = this.createQuery('get', '/api/overviews');
    overview = this.createQuery('get', '/api/overviews/{strategyId}');
    strategy = this.createQuery('get', '/api/strategies/{id}');
    dailyProfit = this.createQuery('get', '/api/strategies/{id}/dailyProfit');
    transactionsStatements = this.createQuery('get', '/api/strategies/{strategyId}/transactions/statements');
}
