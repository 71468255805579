import axios from 'axios-0-27-2';
import { entries } from 'lodash';
import { Fetcher } from 'openapi-typescript-fetch';
import { UrluOfQuery } from '~/modules/SDK/app/urlu/UrluOfQuery';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { __PRODUCTION__ } from '~/utils/__PRODUCTION__';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
/**
 * @example
 *   !! 更多使用情境
 *   !! 請參考 testing
 *
 * @example
 *   //
 *   // 如果你有要區分 prod 和 beta 的一級網域
 *
 *   new Urlu({
 *     prod: 'https://api.futures-ai.com/v1/prod',
 *     beta: 'https://api.futures-ai.com/v1/beta',
 *     alpha: 'https://api.futures-ai.com/v1/alpha',
 *   })
 *
 * @example
 *   //
 *   // 如果你只是要拿 baseUrl 來用
 *   const tradeApi = new Urlu({...})
 *
 *   log(tradeApi.baseUrl)
 *   //
 *   // 或
 *   log(`${tradeApi}/v1/api/kbars`)
 */
export class Urlu {
    baseUrlrc;
    baseUrlUsedEnv;
    /**
     * 可使 axios 的 response 有著正確的 value 和 typing
     *
     * @example
     *   //
     *   intraday = new Urlu.Query((params?: { symbol?: string }) => {
     *     return this.request.axios
     *       .get<{ date: string }>('/latest_trade_date', {
     *         params: {
     *           symbol: params?.symbol || '',
     *           intraday: true,
     *         },
     *       })
     *       .then(
     *         Urlu.transformRequest(res => {
     *           const changed = {
     *             ...res,
     *             data: dayAPI(res.data.date).startOf('day'),
     *           }
     *
     *           return changed
     *         }),
     *       )
     *   })
     */
    // static transformRequest = <Res1 extends AxiosResponse, Res2 extends AxiosResponse>(
    //   transformFunction: (res: Res1) => Res2,
    // ) => {
    //   return transformFunction
    // }
    constructor(baseUrlOrOptions) {
        this.changeBaseUrl(baseUrlOrOptions);
        this.http.configure({
            baseUrl: this.baseUrl,
            use: [
                /** withJwt */
                async (url, init, next) => {
                    init.headers.set('Authorization', `Bearer ${this.jwt}`);
                    const response = await next(url, init);
                    return response;
                },
            ],
        });
    }
    createQuery(method, path) {
        const openapi = this.http.path(path).method(method).create();
        function fetcherCarrier(...params) {
            return openapi(...params).then(res => res.data);
        }
        const urluQuery = new Urlu.Query(fetcherCarrier, {
            namespace: `${method.toString()} ${path.toString()}`,
        });
        fetcherCarrier.useSWR = urluQuery.useSWR;
        fetcherCarrier.useAsync = urluQuery.useAsync;
        fetcherCarrier.request = urluQuery.fetch;
        return fetcherCarrier;
    }
    get baseUrl() {
        return this.baseUrlrc[this.baseUrlUsedEnv] || '';
    }
    get jwt() {
        return fr_me.jwt || useMeStore.getState().meFirebaseState?.jwt;
    }
    changeBaseUrl(baseUrlOrOptions) {
        this.baseUrlrc =
            typeof baseUrlOrOptions === 'string'
                ? {
                    prod: baseUrlOrOptions,
                    beta: baseUrlOrOptions,
                    alpha: baseUrlOrOptions,
                }
                : baseUrlOrOptions.baseUrl;
        if (!this.baseUrlrc.beta) {
            this.baseUrlrc.beta = this.baseUrlrc.prod;
        }
        if (!this.baseUrlrc.alpha) {
            this.baseUrlrc.alpha = this.baseUrlrc.beta;
        }
        this.baseUrlUsedEnv = __PRODUCTION__ ? 'prod' : 'beta';
    }
    /**
     * # @deprecated
     *
     * ## 改用 this.createQuery(...)
     *
     * @deprecated
     * @example
     *   //
     *   // 一般使用，正常用就好（內建含 jwt）
     *   this.request.axios.get()
     *
     *   //
     *   // 如果要額外帶 agent 和 product（內建含 jwt）
     *   this.request.withAgent.withProduct.axios.get()
     *
     *   //
     *   // 如果你特別不需要 jwt 或者是有 jwt 會報錯
     *   // 例如打去 https://storage.googleapis.com
     *   // 就不行有 jwt=undefined
     *   this.request.removeJwt.axios.get()
     *
     * @example
     *   //
     *   // 預設某個 APIs 底下的 async function 通通自帶 agentName
     *   export class TvAPIs extends Urlu {
     *     wsUrl = ''
     *
     *     get request() {
     *       // 從這裡這樣來覆寫父類
     *       return super.request.withAgent.withProduct
     *     }
     *
     *     async fetchHistory() {
     *       this.request.axios.get() // 已自帶 agentName（內建含 jwt）
     *     }
     *   }
     */
    get request() {
        const thisJwt = this.jwt;
        const config = {
            baseURL: this.baseUrl,
            headers: {
                get Authorization() {
                    return `Bearer ${thisJwt}`;
                },
            },
        };
        return {
            get axios() {
                const axiosInstance = axios.create(config);
                /** 使 url 可以直接給例如 `/resource/object/:name` 而不需要額外使用 urlcat */
                axiosInstance.interceptors.request.use(req => {
                    /** 直接使用 req.params 來自動替換上述 url */
                    entries(req.params).forEach(([key, value], index) => {
                        const toFind = `:${key}`;
                        const hasKey = req.url?.includes(toFind);
                        if (!hasKey)
                            return;
                        req.url = req.url?.replaceAll(toFind, String(value));
                        req.params[key] = undefined;
                    });
                    return req;
                });
                axiosInstance.interceptors.response.use(response => response, (error) => {
                    const endpointUrl = `${error.config.baseURL}${error.config.url}`;
                    FrGa.event({
                        API失敗: {
                            errorMessage: error.message,
                            url: endpointUrl,
                        },
                    });
                    return Promise.reject(new Error(`${error.code || 'ERR_HTTP_REQUEST'} ${endpointUrl}`));
                });
                return axiosInstance;
            },
            /**
             * 如果你想另外指定一組 JWT
             *
             * 例如 StrategyAPI 它與 `fr_me.jwt` 不通用
             */
            withJwt(jwt = '') {
                config.headers = {
                    ...config.headers,
                    get Authorization() {
                        return `Bearer ${jwt}`;
                    },
                };
                return this;
            },
            /** - 如果你 `jwt=undefined` 會報錯 */
            get removeJwt() {
                if (!config.headers)
                    return this;
                delete config.headers.Authorization;
                return this;
            },
            /**
             * - 通常只有某些特定 API 需要（by Yucheng）
             * - 附加發送 `?agent=${agent}` 上
             */
            get withAgent() {
                config.params = {
                    ...config.params,
                    agent: fr_agents.agent,
                };
                return this;
            },
            /**
             * - 通常只有某些特定 API 需要（by Yucheng）
             * - 附加發送 `?product=${product}` 上
             */
            get withProduct() {
                config.params = {
                    ...config.params,
                    product: fr_agents.product,
                };
                return this;
            },
        };
    }
    /** 返回 this.baseUrl */
    toString() {
        return this.baseUrl;
    }
    static Query = UrluOfQuery;
    http = Fetcher.for();
}
